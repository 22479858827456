import enLang from './entries/en_US';
import viLang from './entries/vi_VN';
// import zhLang from './entries/zh_CN';
// import frLang from './entries/fr_FR';
// import jaLang from './entries/ja_JP'

const AppLocale = {
    en: enLang,
    vi: viLang,
    // zh: zhLang,
    // fr: frLang,
    // ja: jaLang
};

export default AppLocale;