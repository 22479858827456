const dev = {
    API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL || 'https://jsonplaceholder.typicode.com',
    API_ENDPOINT_URL_TRANSLATE: process.env.REACT_APP_API_ENDPOINT_URL_TRANSLATE || 'https://jsonplaceholder.typicode.com',
    MEDIA_URL: process.env.REACT_APP_MEDIA_URL || ''
};

const prod = {
    API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL || 'https://api.prod.com',
    API_ENDPOINT_URL_TRANSLATE: process.env.REACT_APP_API_ENDPOINT_URL_TRANSLATE || 'https://jsonplaceholder.typicode.com',
    MEDIA_URL: process.env.REACT_APP_MEDIA_URL || ''
};

const test = {
    API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()
