import antdVIVN from 'antd/es/locale/en_US';
import enMsg from "../locales/vi_VN.json";

const ViLang = {
  antd: antdVIVN,
  locale: 'vi',
  messages: {
    ...enMsg
  },
};
export default ViLang;
