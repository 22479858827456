import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/local',
		method: 'post',
		data: data
	})
}
AuthService.getUserMe = function (params) {
	return fetch({
		url: '/user-profile/me',
		method: 'get',
		params
	})
}

// JwtAuthService.signUp = function (data) {
// 	return fetch({
// 		url: '/auth/signup',
// 		method: 'post',
// 		headers: {
//       'public-request': 'true'
//     },
// 		data: data
// 	})
// }

export default AuthService